import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
    genericSort,
    formatName,
    createSurnameFellowsArray,
} from 'common/utils';
import { DUPLICATIONS_SCOPE } from 'common/constants';
import { Box, Row } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import { DuplicationStyled } from './ScheduleRow';

const ScheduleNewColumn = ({ change, data, options, post, publishers }) => {
    const { t } = useTranslation();
    const weekIDs = data.map((row) => {
        if (
            row?.duplicationsScope === DUPLICATIONS_SCOPE.WEEK ||
            row?.duplicationsScope === DUPLICATIONS_SCOPE.BOTH
        ) {
            return row.publisherID;
        }
    });
    const weekendIDs = data.map((row) => {
        if (
            row?.duplicationsScope === DUPLICATIONS_SCOPE.WEEKEND ||
            row?.duplicationsScope === DUPLICATIONS_SCOPE.BOTH
        ) {
            return row.publisherID;
        }
    });

    const rowsList = [];

    const surnameFellows = createSurnameFellowsArray(publishers);

    const duplicationsColors = {
        2: { bg: 'rgba(83, 198, 233, 0.9)', cl: '#ffffff' },
        3: { bg: 'rgba(233, 186, 83, 0.9)', cl: '#ffffff' },
        4: { bg: 'rgba(250, 116, 54, 0.9)', cl: '#ffffff' },
        5: { bg: 'rgba(247, 16, 16, 0.9)', cl: '#ffffff' },
    };

    const getDuplicationColors = (duplicateNum, isColor) => {
        const propertyName = isColor ? 'cl' : 'bg';
        if (duplicateNum < 5) {
            return duplicationsColors[duplicateNum]?.[propertyName];
        } else {
            return duplicationsColors['5']?.[propertyName];
        }
    };

    const getDuplicateNum = (id, scope) => {
        if (!id || !scope) {
            return 0;
        }
        if (scope === DUPLICATIONS_SCOPE.WEEK) {
            return weekIDs.filter((weekID) => weekID === id).length;
        }
        if (scope === DUPLICATIONS_SCOPE.WEEKEND) {
            return weekendIDs.filter((weekID) => weekID === id).length;
        }
        if (scope === DUPLICATIONS_SCOPE.BOTH) {
            return Math.max(
                weekIDs.filter((weekID) => weekID === id).length,
                weekendIDs.filter((weekID) => weekID === id).length
            );
        }
    };

    data.forEach((row) => {
        const duplicateNum = getDuplicateNum(
            row?.publisherID,
            row?.duplicationsScope
        );

        const isSelect = row?.type === 'select' || !row?.type;

        rowsList.push(
            <RowStyled>
                <Input
                    key={row.id}
                    type={row?.type || 'select'}
                    placeholder={isSelect ? undefined : row?.placeholder}
                    maxLength={isSelect ? undefined : row?.maxlength}
                    value={isSelect ? row?.publisherID : row.value}
                    onChange={(e) => change(e, row.id)}
                    data={row}
                    borderRadius="0.8rem"
                    height="27px"
                    width="100%"
                    margin="0.5px 0"
                    padding="0 15px 0 0"
                    noBorder
                >
                    {options[row.optionsCategory]?.length &&
                        genericSort(
                            genericSort(
                                options[row.optionsCategory],
                                false,
                                'text',
                                'name'
                            ),
                            false,
                            'text',
                            'surname'
                        ).map((option, i) => {
                            const formattedName = formatName(
                                option,
                                surnameFellows
                            );

                            return (
                                <option key={i} value={option?.publisherID}>
                                    {formattedName}
                                </option>
                            );
                        })}
                </Input>
                {isSelect && duplicateNum > 1 && (
                    <DuplicationStyled
                        background={getDuplicationColors(duplicateNum)}
                        color={getDuplicationColors(duplicateNum, true)}
                        class="duplicationNum"
                    >
                        {duplicateNum}
                    </DuplicationStyled>
                )}
            </RowStyled>
        );
    });

    return (
        !!rowsList.length && (
            <ColumnStyled>
                {rowsList}
                <Button
                    text={t('Pridať stĺpec')}
                    type="green"
                    onClick={post}
                    icon="plus"
                    my="5px"
                />
            </ColumnStyled>
        )
    );
};

export default ScheduleNewColumn;

const RowStyled = styled(Row)`
    width: 100%;
    position: relative;
    align-items: center;
`;

const ColumnStyled = styled(Box)`
    align-items: center;
    margin: 0px 5px;
    min-width: fit-content;
`;
